import { useEffect } from 'react';
import history from '../utils/history';

const isFirefoxAndroid = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  return agent.indexOf('firefox') >= 0 && agent.indexOf('android') >= 0;
};

const useSupported = () => {
  useEffect(() => {
    if (isFirefoxAndroid()) {
      history.push(`/error?code=not-supported`);
    }
  });
};

export default useSupported;
