import { AUTHORIZATION_KEY } from '../../constants';
import * as Note from './Note';
import * as Inspection from './Inspection';
import * as Organization from './Organization';
import * as Settings from './Settings';
import * as InspectionTypes from './InspectionTypes';

export const ENDPOINT = process.env.REACT_APP_ENDPOINT || '/api';
export const HEADERS = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const makeHeaders = noAuth => {
  if (noAuth) return HEADERS;

  try {
    return {
      ...HEADERS,
      Authorization: JSON.parse(window.localStorage.getItem(AUTHORIZATION_KEY)),
    };
  } catch (error) {
    console.error(`Error trying to parse token`);
    return {
      ...HEADERS,
      Authorization: null,
    };
  }
};

export const request = (...args) => {
  return window.fetch(...args);
};

export const NoteService = Note;
export const InspectionService = Inspection;
export const OrganizationService = Organization;
export const SettingsService = Settings;
export const InspectionTypesService = InspectionTypes;

/**
 * Simple service wrapper to integrate with `useDataApi` hook
 * @param method
 * @param uri
 * @param body
 * @param opts
 * @returns {Promise<Response>}
 */
export const service = (method, uri, body, opts) => {
  return fetch(`${ENDPOINT}${uri}`, {
    method,
    mode: 'cors',
    headers: makeHeaders(),
    ...(body && { body: JSON.stringify(body) }),
    ...opts,
  }).then(response => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      error.status = response.status;
      throw error;
    }
    if (response.status === 204) return true;
    const contentType = response.headers.get('Content-Type');
    switch (contentType) {
      case 'text/csv':
      case 'application/zip':
        return response.blob();
      default:
        return response.json();
    }
  });
};
