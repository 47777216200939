import React from 'react';
import PropTypes from 'prop-types';

// Local updated wrapper for Highcharts from Highchart's official repo.
// https://github.com/highcharts/highcharts-react
export default class HighchartsReact extends React.PureComponent {
  static propTypes = {
    highcharts: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    constructorType: PropTypes.string,
    allowChartUpdate: PropTypes.bool,
    updateArgs: PropTypes.object,
    callback: PropTypes.func,
  };

  static defaultProps = {
    constructorType: null,
    allowChartUpdate: true,
    updateArgs: null,
    callback: null,
  };

  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount() {
    const highcharts = this.props.highcharts || window.Highcharts;
    // Create chart
    this.chart = highcharts[this.props.constructorType || 'chart'](
      this.container.current,
      this.props.options,
      this.props.callback ? this.props.callback : undefined,
    );
  }

  componentDidUpdate() {
    if (this.props.allowChartUpdate !== false) {
      this.chart.update(this.props.options, ...(this.props.updateArgs || [true, true]));
    }
  }

  componentWillUnmount() {
    // Destroy chart
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  render() {
    // Create container for the chart
    return React.createElement('div', { ref: this.container });
  }
}
