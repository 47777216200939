import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../utils/history';
import Permissions from '../api/Permissions';
import { setPermissionStatus } from '../redux/actions';

export { default as Permissions } from '../api/Permissions';

const usePermissions = (isAdmin, videoOnlyModeEnabled = false) => {
  const dispatch = useDispatch();
  const status = useSelector(state => state.permissionsStatus);

  let required = [];
  const optional = isAdmin ? [] : [Permissions.Location];

  if (isAdmin) {
    required = videoOnlyModeEnabled ? [] : [Permissions.Audio];
  } else {
    required = videoOnlyModeEnabled ? [Permissions.Video] : [Permissions.Audio, Permissions.Video];
  }

  const checkPermissions = () => {
    Permissions.checkPermissions(required, optional).then(statuses => {
      const denied = statuses.find(({ status }) => status === Permissions.Denied);

      if (!denied) {
        return dispatch(setPermissionStatus(Permissions.Approved));
      }

      dispatch(setPermissionStatus(Permissions.Denied));
      history.push(`/help/${denied.name}`);
    });
  };

  useEffect(
    () => {
      if (status === Permissions.Unknown) {
        checkPermissions();
      }
    },
    [status, optional, required, dispatch],
  );

  return [status, checkPermissions];
};

export default usePermissions;
