import { useEffect, useState } from 'react';
import { useScreen } from './useScreen';

const useMobileDetect = () => {
  const [screenType] = useScreen();
  const [isNativeMobile, setMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
      ),
    );
    setMobile(mobile);
    setIsIOS(userAgent.match(/iPhone|iPad|iPod/i));
  }, []);

  const isMobile = isNativeMobile || screenType === 'Mobile';
  const isTablet = screenType === 'Tablet';

  return {
    isMobile,
    isTablet,
    isDesktop: !isMobile,
    isIOS,
  };
};

export default useMobileDetect;
