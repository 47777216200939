import * as Sentry from '@sentry/browser';
import { _LTracker } from 'loggly-jslogger';
import { CaptureConsole } from '@sentry/integrations';

const { NODE_ENV, REACT_APP_LOGGLY_AUTH_TOKEN } = process.env;

const IS_PRODUCTION = NODE_ENV === 'production';

export const init = () => {
  if (IS_PRODUCTION) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new CaptureConsole({ levels: ['error'] })],
      environment: process.env.SENTRY_ENV,
    });
  }

  Logger.init({
    authToken: REACT_APP_LOGGLY_AUTH_TOKEN,
  });
};

export const Logger = {
  init({ authToken }) {
    this.send({
      logglyKey: authToken,
      sendConsoleErrors: true,
      tag: `frontend,${NODE_ENV.toLowerCase()}`,
    });
  },

  send(payload) {
    const referrer = window.location.href;
    const message = { referrer, ...payload };
    if (IS_PRODUCTION) {
      _LTracker.push(message);
    } else {
      console.info('Logger:', message);
    }
  },

  info(viInfo) {
    Logger.send({ level: 'INFO', viInfo });
  },
};
