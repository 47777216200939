import moment from 'moment-timezone';

export function getFormattedDateString(dateString, format = 'LL') {
  return moment(dateString).format(format);
}

export function getFormattedTimeString(dateString, format = 'LL, h:mm:ss A') {
  return moment(dateString).format(format);
}

export function setAppTimezone(timezone) {
  if (timezone) {
    moment.tz.setDefault(timezone);
  }
}

export const predefinedRanges = () => ({
  Yesterday: [moment().subtract(1, 'day'), moment()],
  'Last Week': [moment().subtract(1, 'week'), moment()],
  'Last Month': [moment().subtract(1, 'month'), moment()],
  'Last Year': [moment().subtract(1, 'year'), moment()],
});

export const momentizedDates = dates => {
  try {
    if (!Array.isArray(dates)) {
      return null;
    }
    return dates.map(date => (date ? moment(date) : moment()));
  } catch (error) {
    return [moment(), moment().add(7, 'days')];
  }
};

export const formatTimeOfDay = tod => {
  switch (tod) {
    case 'AM':
      return 'AM (Morning)';
    case 'PM':
      return 'PM (Afternoon)';
    default:
      return 'No Preference';
  }
};

export const formatDate = date => moment(date).format('MMMM D, YYYY');
