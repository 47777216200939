import { useCallback, useContext, useMemo } from 'react';
import queryString from 'query-string';
import { SearchParamContext } from '../context';

/**
 * Handler function that updates history params
 * @param action
 * @param payload
 * @param history
 */
const updateParams = ({ action, payload, history }) => {
  switch (action.type) {
    case 'push':
    case 'replace':
      history[action.type]({ search: payload });
      break;
    default:
    // Unknown update type for router
  }
};

/**
 * Hook to get/set search query params that hooks into a context provider
 */
const useSearchParams = defaultParams => {
  const { history, location } = useContext(SearchParamContext);

  const searchParams = useMemo(() => queryString.parse(location.search) || {}, [
    location.search,
  ]);

  const setSearchParams = useCallback(
    (params, type = 'push') => {
      updateParams({
        action: {
          type,
        },
        payload: queryString.stringify(params),
        location,
        history,
      });
    },
    [location],
  );

  return [
    {
      currentSearch: location.search,
      location,
      history,
      searchParams: {
        ...defaultParams,
        ...searchParams,
      },
      encodeSearchParams: queryString.stringify,
    },
    setSearchParams,
  ];
};

export default useSearchParams;
