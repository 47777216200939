import queryString from 'query-string';
import { ENDPOINT, request, makeHeaders } from './';

export const list = async organizationId => {
  const params = queryString.stringify({ organizationId });
  try {
    const uri = `${ENDPOINT}/inspectionTypes?${params}`;
    const response = await request(uri, { method: 'GET' });
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const create = async (organizationId, { name }) => {
  const body = JSON.stringify({ organizationId, name });
  const uri = `${ENDPOINT}/inspectionTypes`;
  const response = await request(uri, { method: 'POST', headers: makeHeaders(), body });
  return await response.json();
};

export const update = async (inspectionTypeId, { name, organizationId }) => {
  const body = JSON.stringify({ name, organizationId });
  const uri = `${ENDPOINT}/inspectionTypes/${inspectionTypeId}`;
  const response = await request(uri, { method: 'PUT', headers: makeHeaders(), body });
  return await response.json();
};

export const destroy = async (organizationId, inspectionTypeId) => {
  const body = JSON.stringify({ organizationId });
  const uri = `${ENDPOINT}/inspectionTypes/${inspectionTypeId}`;
  const response = await request(uri, { method: 'DELETE', headers: makeHeaders(), body });
  return await response.json();
};
