import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import moment from 'moment-timezone';
import trimStart from 'lodash/trimStart';
import './TimeLineChart.scss';

import HighchartsReact from './highCharts';

export const START_DAY = 2;
export function getTimelineFormattedDate({ month, day, hour, minute, timeZoneString }) {
  const monthString = `${month || 1}`.padStart(2, '0');
  const dayString = `${day || START_DAY}`.padStart(2, '0');
  const hourString = `${hour || 0}`.padStart(2, '0');
  const minuteString = `${minute || 0}`.padStart(2, '0');
  const timeZone = timeZoneString ? trimStart(timeZoneString, 'GMT') : '';

  const momentDate = moment(
    new Date(
      `2018-${monthString}-${dayString}T${hourString}:${minuteString}:00${timeZone}`,
    ),
  );

  momentDate.add(momentDate.utcOffset(), 'minutes');
  return momentDate.valueOf();
}

const TIMELINE_START = getTimelineFormattedDate({
  month: 0,
  day: START_DAY - 1,
  hour: 23,
  minute: 1,
});
const TIMELINE_END = getTimelineFormattedDate({
  month: 0,
  day: START_DAY + 1,
  hour: 0,
  minute: 59,
});

export function getTimelineBandOptions(hour = 0, delta = 3) {
  const startHour = hour % 24;
  const startDay = START_DAY + Math.floor(hour / 24);
  const hourDelta = hour + delta;
  const endHour = hourDelta % 24;
  const endDay = startDay + Math.floor(hourDelta / 24);
  return {
    color: '#fcfcfc',
    from: getTimelineFormattedDate({ hour: startHour, day: startDay }),
    to: getTimelineFormattedDate({ hour: endHour, day: endDay }),
  };
}

const PLOT_BANDS = [
  getTimelineBandOptions(0),
  getTimelineBandOptions(6),
  getTimelineBandOptions(12),
  getTimelineBandOptions(18),
  getTimelineBandOptions(24),
];

const timelineOptions = {
  chart: {
    renderTo: 'container',
    type: 'column',
  },
  colors: ['#1e7bde'],
  title: {
    text: '',
  },
  legend: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: 'Number of Visits',
    },
    min: 0,
    gridLineWidth: 0,
    tickWidth: 1,
    lineWidth: 1,
  },
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
    gridLineDashStyle: 'longdash',
    labels: {
      formatter() {
        return Highcharts.dateFormat('%l %p', this.value);
      },
    },
    title: {
      text: 'Time of Day',
    },
    plotBands: PLOT_BANDS,
    tickInterval: 3 * 3600 * 1000, // 3 hours
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  credits: false,
};

export default class TimeLineChart extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    timelineStart: PropTypes.number,
    timelineEnd: PropTypes.number,
  };

  static defaultProps = {
    data: [],
    timelineStart: TIMELINE_START,
    timelineEnd: TIMELINE_END,
  };

  getOptions() {
    const { data, timelineStart, timelineEnd } = this.props;
    const options = { ...timelineOptions };
    options.xAxis.min = timelineStart;
    options.xAxis.max = timelineEnd;
    options.series = [
      {
        data,
      },
    ];
    return options;
  }

  render() {
    return <HighchartsReact highcharts={Highcharts} options={this.getOptions()} />;
  }
}
