import React from 'react';
import { Calendar } from 'antd';

import IconButton from '../IconButton';
import Text from '../Text';

import styles from './CalendarField.scss';

const CalendarHeader = ({ value, onChange }) => {
  const handlePrevMonth = () => {
    onChange(value.clone().subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    onChange(value.clone().add(1, 'month'));
  };

  return (
    <div className={styles.header}>
      <IconButton icon="caret_left" onClick={handlePrevMonth} />
      <Text size="large">{value.format('MMMM, YYYY')}</Text>
      <IconButton icon="caret_right" onClick={handleNextMonth} />
    </div>
  );
};

const CalendarField = ({ input, meta, label, ...others }) => (
  <div className={styles.wrapper}>
    <Text as="span" color="grey" size="medium" uppercase weight="medium">
      {label}
    </Text>
    <div className={styles.calendarWrapper}>
      <Calendar
        {...others}
        fullscreen={false}
        headerRender={CalendarHeader}
        value={input.value}
        onChange={input.onChange}
      />
    </div>
  </div>
);

export default CalendarField;
