import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { AUTHORIZATION_KEY } from '../constants';
import { useLocalStorage, useSearchParams } from '../hooks';
import { InspectionService } from '../api/services';
import { AppContext } from '../context';

const authRedirectUrl = (organizationId, redirectTo) =>
  `${process.env.REACT_APP_AUTH_REDIRECT}?return_to=${redirectTo}${
    organizationId ? `&organization_id=${organizationId}` : ''
  }`;

const ProtectedRoute = props => {
  const [redirectTo, setRedirectTo] = useState(null);
  const { organizationId, token: currentAppToken } = useContext(AppContext);
  const [{ searchParams }] = useSearchParams();
  const [, , removeToken] = useLocalStorage(AUTHORIZATION_KEY, '');
  const token = searchParams.token || currentAppToken;

  // Check or verify token, redirect if neither exist currently
  useEffect(() => {
    (async function () {
      try {
        if (!token || !(await InspectionService.auth())) {
          setRedirectTo(
            authRedirectUrl(organizationId, encodeURIComponent(window.location.origin)),
          );
        }
      } catch (error) {
        console.error(error);
        removeToken();
      }
    })();
  }, [token, organizationId]);

  // Redirect as needed
  useEffect(() => {
    if (searchParams.return_to) {
      setRedirectTo(searchParams.return_to);
    }
  }, [searchParams.return_to]);

  return redirectTo ? (
    <Route render={() => (window.location = decodeURIComponent(redirectTo))} />
  ) : (
    <Route {...props} />
  );
};

export default ProtectedRoute;
