import React, { useContext } from 'react';
import template from 'lodash/template';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';
import { Text } from '@caminoai/ui';
import { AppContext, CitizenPortalContext } from '../context';

const defaultFallbackTerminology = {
  inspection: {
    singular: 'inspection',
    plural: 'inspections',
  },
};

export default function useTerminology(
  opts = {},
  fallbackTerminology = defaultFallbackTerminology,
) {
  const { isCitizenPortal = false } = opts;

  const { organization, setOrganization } = useContext(
    isCitizenPortal ? CitizenPortalContext : AppContext,
  );

  const terminology = organization?.terminology || fallbackTerminology;

  const uppercasedTerminology = reduce(
    terminology,
    (acc, value, key) => {
      acc[key] = {
        singular: startCase(value.singular),
        plural: startCase(value.plural),
      };
      return acc;
    },
    {},
  );

  const interpolate = (text, opts = { uppercase: false }) =>
    template(text)(opts.uppercase ? uppercasedTerminology : terminology);

  const Terminology = ({ path, text, opts, ...rest }) => {
    return <Text {...rest}>{interpolate(text, opts)}</Text>;
  };

  return {
    setTerminology: t =>
      setOrganization({
        ...organization,
        terminology: t,
      }),
    interpolate,
    Terminology,
  };
}
