import { useEffect, useState } from 'react';
import { InspectionService } from '../api/services';

const watch = async (id, onPermissionError, onPermissionResolved) => {
  if (!id) return;

  const request = async () => {
    try {
      const data = await InspectionService.fetch(id);

      if (data.attendeeEncounteredPermissionsError) {
        onPermissionError();
      } else {
        onPermissionResolved();
      }

      if (data.attendeeName) {
        window.clearInterval(timer);
      }
    } catch (e) {
      console.error(e);
      window.clearInterval(timer);
    }
  };

  let timer = window.setInterval(request, 2000);

  return () => {
    window.clearInterval(timer);
  };
};

const useWatchInspection = id => {
  const [hasPermissionError, setHasPermissionError] = useState(false);

  const onPermissionError = () => setHasPermissionError(true);
  const onPermissionResolved = () => setHasPermissionError(false);

  useEffect(() => {
    watch(id, onPermissionError, onPermissionResolved);
  }, []);

  return hasPermissionError;
};

export default useWatchInspection;
