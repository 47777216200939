import React from 'react';
import { RawButton as Button, Heading } from '@caminoai/ui';
import errorGraphic from '../../assets/error.svg';
import styles from './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { handlingError: false };
  }

  static getDerivedStateFromError(error) {
    return { handlingError: true };
  }

  refresh = () => {
    window.location.reload();
  };

  componentDidCatch(error, errorInfo) {
    // Logs to analytics as well
    console.error(error); // eslint-disable-line
  }

  render() {
    const { displayEmailMessage } = this.props;
    const { handlingError } = this.state;
    return handlingError ? (
      <div id="error-boundary" className={styles.ErrorWrapper}>
        <div className={styles.ErrorCard}>
          <img height="200" width="200" src={errorGraphic} alt="Error" />
          <Heading size="larger">Uh oh... looks like something went wrong.</Heading>
          <div className={styles.TextContent}>
            <p>There was an unexpected error. Our team of engineers has been notified.</p>
            <p>In the meantime, please try refreshing the page.</p>
          </div>
          <Button id="error-boundary-refresh-button" onClick={this.refresh}>
            Click here to refresh
          </Button>
        </div>
        {displayEmailMessage && (
          <div id="error-boundary-display-message" className={styles.EmailMessage}>
            If you are experiencing this frequently, please email{' '}
            <a
              id="error-boundary-email-link"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:support@oncamino.com"
            >
              support@oncamino.com
            </a>
          </div>
        )}
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
