import { v4 as uuidv4 } from 'uuid';

import flatten from 'lodash/flatten';
import { createTrack, removeTracks } from './utils';

export default class Participant {
  constructor(identity, isInspector, participant = null) {
    this.network = 5;
    this.video = null;
    this.audio = null;
    this.id = uuidv4();
    this.zoomLevel = 1;
    this.flashlightToggled = false;
    this.pointerToggled = false;
    this.position = null;
    this.audioMuted = false;
    this.videoMuted = false;
    this.identity = identity;
    this.networkStatistics = null;
    this.participant = participant;
    this.isInspector = !!isInspector;
    if (participant) {
      this.network = participant.networkQualityLevel;
      this.networkStatistics = participant.networkQualityStats;
    }
  }

  setZoomLevel(zoom) {
    this.zoomLevel = Math.max(Math.min(3, zoom), 1);
  }

  destroy() {
    [this.video, this.audio].forEach(removeTracks);
    this.video = null;
    this.audio = null;
  }

  setVideo(stream) {
    const track = stream.getVideoTracks()[0];
    this.video = new MediaStream([track]);
  }

  setAudio(stream) {
    const track = stream.getAudioTracks()[0];
    this.audio = new MediaStream([track]);
  }

  getTracks() {
    return flatten(
      [this.audio, this.video]
        .filter(v => !!v)
        .map(stream => stream.getTracks().map(t => createTrack(t, this.isInspector))),
    );
  }

  setNetwork(network) {
    this.network = network;
  }

  setNetworkStatistics(statistics) {
    this.networkStatistics = statistics;
  }

  startAudio() {
    if (this.participant) {
      this.participant.audioTracks.forEach(publication => {
        publication.track.enable();
        this.audioMuted = false;
      });
    }
  }

  stopAudio() {
    if (this.participant) {
      this.participant.audioTracks.forEach(publication => {
        publication.track.disable();
        this.audioMuted = true;
      });
    }
  }

  startVideo() {
    if (this.participant) {
      this.participant.videoTracks.forEach(publication => {
        publication.track.enable();
        this.videoMuted = false;
      });
    }
  }

  stopVideo() {
    if (this.participant) {
      this.participant.videoTracks.forEach(publication => {
        publication.track.disable();
        this.videoMuted = true;
      });
    }
  }

  setPointer(pointer) {
    this.pointer = pointer;
  }

  toggleFlashlight(flashlightToggled) {
    this.flashlightToggled = flashlightToggled;
  }

  togglePointer(pointerToggled) {
    this.pointerToggled = pointerToggled;
  }
}
