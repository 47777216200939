import { ENDPOINT, request, makeHeaders } from './';

export const create = async (id, data) => {
  const body = new FormData();

  if (data.image) {
    const imageResponse = await request(data.image);
    const blob = await imageResponse.blob();
    const capture = new File([blob], `capture.png`, { type: 'image/png' });
    body.append('capture', capture);
  }

  body.append('text', data.text);
  body.append('captured_at', data.date.toString());

  const { Authorization } = makeHeaders();

  const response = await request(`${ENDPOINT}/inspections/${id}/notes`, {
    body,
    method: 'post',
    headers: { Authorization },
  });

  return await response.json();
};

export const update = async (id, data) => {
  const body = new FormData();

  if (data.image) {
    const imageResponse = await request(data.image);
    const blob = await imageResponse.blob();
    const capture = new File([blob], `capture.png`, { type: 'image/png' });
    body.append('capture', capture);
  }

  body.append('text', data.text);
  body.append('captured_at', data.date.toString());

  const { Authorization } = makeHeaders();

  const response = await request(`${ENDPOINT}/notes/${id}`, {
    body,
    method: 'put',
    headers: { Authorization },
  });

  return await response.json();
};

export const destroy = async id => {
  await request(`${ENDPOINT}/notes/${id}`, { method: 'delete', headers: makeHeaders() });
};

export const fetch = async id => {
  const response = await request(`${ENDPOINT}/inspections/${id}/notes`, {
    method: 'get',
    headers: makeHeaders(),
  });
  return await response.json();
};

export const fetchOne = async id => {
  const response = await request(`${ENDPOINT}/notes/${id}`, {
    method: 'get',
    headers: makeHeaders(),
  });
  return await response.json();
};
