import { Permissions } from '../hooks/usePermissions';
import { v4 as uuidv4 } from 'uuid';

export { reducer as form } from 'redux-form';

const initialNotificationState = {
  notifications: [],
};

export const notificationState = (state = initialNotificationState, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        notifications: [...state.notifications, { id: uuidv4(), ...action.payload }],
      };
    case 'CLEAR_NOTIFICATION': {
      if (Array.isArray(state.notifications)) {
        const newNotifications = [...state.notifications];
        newNotifications.pop();
        return {
          notifications: newNotifications,
        };
      }
      return initialNotificationState;
    }
    default:
      return state;
  }
};

export const permissionsStatus = (state = Permissions.Unknown, action) => {
  switch (action.type) {
    case 'SET_PERMISSION_STATUS':
      return action.payload.status;
    default:
      return state;
  }
};

export const inspectionsState = (
  state = { rows: [], count: 0, isLoading: false },
  action,
) => {
  switch (action.type) {
    case 'SET_INSPECTIONS':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const inspectionState = (state = {}, action) => {
  switch (action.type) {
    case 'SET_INSPECTION':
      return { ...action.payload, identity: state.identity };
    case 'SET_IDENTITY':
      return { ...state, identity: action.payload };
    case 'CLEAR_IDENTITY':
      return { ...state, identity: undefined };
    case 'SET_CREATE_INSPECTION_ERROR':
      return { ...state, createInspectionError: action.payload };
    default:
      return state;
  }
};

const DEFAULT_NOTE_STATE = {
  notes: [],
  capture: null,
  editingMode: false,
};

export const noteState = (state = DEFAULT_NOTE_STATE, action) => {
  switch (action.type) {
    case 'SET_NOTES':
      return { ...DEFAULT_NOTE_STATE, notes: action.payload };
    case 'ADD_NOTE':
      return {
        ...state,
        capture: null,
        editingMode: false,
        remoteCapture: null,
        notes: [...state.notes, action.payload],
      };
    case 'SET_EDITING_MODE':
      return {
        ...state,
        capture: null,
        remoteCapture: null,
        editingMode: action.payload,
      };
    case 'SET_CAPTURE':
      return {
        ...state,
        remoteCapture: null,
        editingMode: 'CAPTURE',
        capture: action.payload,
      };
    case 'SET_REMOTE_CAPTURE':
      return {
        ...state,
        editingMode: 'CAPTURE',
        remoteCapture: action.payload,
      };
    default:
      return state;
  }
};

export const infoState = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_INFO_MODAL':
      return !state;
    default:
      return state;
  }
};

const DEFAULT_DISCLAIMER_STATE = {
  disclaimer: null,
};

export const disclaimerState = (state = DEFAULT_DISCLAIMER_STATE, action) => {
  switch (action.type) {
    case 'SET_DISCLAIMER':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const CUSTOM_REQUEST_TEXT = {
  requestFormCustomText: '',
};

export const customRequestState = (state = CUSTOM_REQUEST_TEXT, action) => {
  switch (action.type) {
    case 'SET_CUSTOM_REQUEST_TEXT':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
