import Participant from './Participant';

import { sleep } from '../utils';

export default class RemoteParticipant extends Participant {
  setPosition(position) {
    this.position = position;
  }

  async setAudio(stream) {
    super.setAudio(stream);

    // HACK for iOS bug https://github.com/twilio/twilio-video.js/issues/645
    await sleep(5000);
    const track = stream.getAudioTracks()[0];
    track.enabled = false;
    track.enabled = true;
  }
}
