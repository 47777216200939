import { DataExchange } from 'aws-sdk';
import { ENDPOINT, request, makeHeaders } from './';

export const fetchDisclaimer = async organizationId => {
  if (!organizationId) {
    return null;
  }
  try {
    const uri = `${ENDPOINT}/organizations/${organizationId}/settings/disclaimer`;
    const response = await request(uri, { method: 'get', headers: makeHeaders() });
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const fetchTerminology = async organizationId => {
  if (!organizationId) {
    return null;
  }
  try {
    const uri = `${ENDPOINT}/organizations/${organizationId}/settings/terminology`;
    const { terminology } = await request(uri, {
      method: 'get',
      headers: makeHeaders(),
    }).then(res => res.json());
    return terminology;
  } catch (e) {
    return {};
  }
};

export const fetchRequestText = async organizationId => {
  if (!organizationId) {
    return null;
  }
  try {
    const uri = `${ENDPOINT}/organizations/${organizationId}/settings/request`;
    const response = await request(uri, { method: 'get', headers: makeHeaders() });
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const update = async (organizationId, data) => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/organizations/${organizationId}/settings`, {
    method: 'put',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const addResult = async (organizationId, { newResult }) => {
  const uri = `${ENDPOINT}/organizations/${organizationId}/settings/results`;
  const body = JSON.stringify({ newResult });
  const response = await request(uri, { method: 'post', body, headers: makeHeaders() });
  const json = await response.json();

  if (!response.ok) {
    console.log(json.error);
    throw new Error(
      json?.error ||
        `There was an error while adding the result "${newResult}". Please try again.`,
    );
  }

  return json;
};

export const updateResult = async (organizationId, { oldResult, newResult }) => {
  const uri = `${ENDPOINT}/organizations/${organizationId}/settings/results`;
  const body = JSON.stringify({ oldResult, newResult });
  const response = await request(uri, { method: 'put', body, headers: makeHeaders() });
  const json = await response.json();

  if (!response.ok) {
    throw new Error(
      json?.error ||
        `There was an error while updating the result "${oldResult}" to "${newResult}". Please try again.`,
    );
  }

  return json;
};

export const deleteResult = async (organizationId, { resultToDelete }) => {
  const uri = `${ENDPOINT}/organizations/${organizationId}/settings/results`;
  const body = JSON.stringify({ resultToDelete });
  const response = await request(uri, { method: 'delete', body, headers: makeHeaders() });
  const json = await response.json();

  if (!response.ok) {
    throw new Error(
      json?.error ||
        `There was an error while deleting the result "${resultToDelete}". Please try again.`,
    );
  }

  return json;
};
