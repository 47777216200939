export const scale = (inputY, yRange, xRange) => {
  const [xMin, xMax] = xRange;
  const [yMin, yMax] = yRange;
  const percent = (inputY - yMin) / (yMax - yMin);
  const outputX = percent * (xMax - xMin) + xMin;
  return outputX;
};

export const clamp = (value, min, max) => {
  return Math.max(Math.min(value, max), min);
};

export const getPoint = ({ x, y }, videoDimensions, canvasDimensions) => {
  const videoRatio = videoDimensions.width / videoDimensions.height;
  let width = canvasDimensions.width;
  let height = canvasDimensions.height;
  const elementRatio = width / height;
  if (elementRatio > videoRatio) width = height * videoRatio;
  else height = width / videoRatio;
  const r1 = (canvasDimensions.width - width) / 2;
  const r2 = (canvasDimensions.height - height) / 2;
  const x1 = Math.min(Math.max(x - r1, 0), width);
  const y1 = Math.min(Math.max(y - r2, 0), height);
  const x2 = scale(x1, [0, width], [0, videoDimensions.width]);
  const y2 = scale(y1, [0, height], [0, videoDimensions.height]);
  const point = { x: x2, y: y2 };
  return point;
};

export const getFromPoint = ({ x, y }, src, dst) => {
  const scaledX = scale(x, [0, src.width], [0, dst.width]);
  const scaledY = scale(y, [0, src.height], [0, dst.height]);
  const clampedX = clamp(scaledX, 0, dst.width);
  const clampedY = clamp(scaledY, 0, dst.height);
  return { x: clampedX + dst.x, y: clampedY + dst.y };
};

export const drawPointer = ({ config, canvas, point, name, isFixed }) => {
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.beginPath();
  ctx.arc(point.x, point.y, 7, 0, 2 * Math.PI, false);
  ctx.fillStyle = config.fillStyle || '#1890ff';
  ctx.fill();

  if (!isFixed) {
    const height = 15;
    const width = ctx.measureText(name).width + 10;
    let radius = 3;
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;

    ctx.beginPath();
    ctx.moveTo(point.x + radius, point.y - 25);
    ctx.arcTo(
      point.x + width,
      point.y - 25,
      point.x + width,
      point.y - 25 + height,
      radius,
    );
    ctx.arcTo(
      point.x + width,
      point.y - 25 + height,
      point.x,
      point.y - 25 + height,
      radius,
    );
    ctx.arcTo(point.x, point.y - 25 + height, point.x, point.y - 25, radius);
    ctx.arcTo(point.x, point.y - 25, point.x + width, point.y - 25, radius);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = '#fff';
    ctx.fillText(name, point.x + 5, point.y - 14);
  }
};

const getOffsets = (target, source, zoom = 1) => {
  const sourceAspectRatio = source.width / source.height;
  const targetAspectRatio = target.width / target.height;
  let renderableHeight, renderableWidth, xStart, yStart;

  if (sourceAspectRatio < targetAspectRatio) {
    renderableHeight = target.height;
    renderableWidth = source.width * (renderableHeight / source.height);
    xStart = (target.width - renderableWidth) / 2;
    yStart = 0;
  } else if (sourceAspectRatio > targetAspectRatio) {
    renderableWidth = target.width;
    renderableHeight = source.height * (renderableWidth / source.width);
    xStart = 0;
    yStart = (target.height - renderableHeight) / 2;
  } else {
    renderableHeight = target.height;
    renderableWidth = target.width;
    xStart = 0;
    yStart = 0;
  }

  const x = xStart + (renderableWidth - renderableWidth * zoom) / 2;
  const y = yStart + (renderableHeight - renderableHeight * zoom) / 2;
  return { x, y, width: renderableWidth * zoom, height: renderableHeight * zoom };
};

export const fitFrameOnCanvas = (ctx, video, target, source, zoom = 1) => {
  const { x, y, width, height } = getOffsets(target, source, zoom);
  ctx.drawImage(video, x, y, width, height);
};

export const canvasRenderer = ({
  canvas,
  video,
  participants,
  dimensions,
  zoom = 1,
  pointerConfig = {},
}) => {
  const redraw = () => {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const sourceDimensions = { width: video.videoWidth, height: video.videoHeight };
    const offsets = getOffsets(dimensions, sourceDimensions, zoom);
    ctx.drawImage(video, offsets.x, offsets.y, offsets.width, offsets.height);

    participants.forEach(({ pointer, name }) => {
      if (pointer && pointer.visible) {
        const { width, height, x, y } = pointer;
        const point = getFromPoint({ x, y }, { width, height }, offsets);
        drawPointer({
          canvas,
          point,
          name,
          isFixed: pointer.fixed,
          config: pointerConfig[pointer.fixed ? 'fixed' : 'notFixed'],
        });
      }
    });

    requestAnimationFrame(redraw);
  };

  redraw();
};
