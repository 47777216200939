import Video from 'twilio-video';

import DataTrack from '../DataTrack';
import Participant from './Participant';

export default class LocalParticipant extends Participant {
  unpublish() {
    if (this.participant) {
      const tracks = Array.from(this.participant.tracks.values()).map(p => p.track);
      tracks.forEach(track => this.participant.unpublishTrack(track));
    }
  }

  publish() {
    if (this.participant) {
      this.getTracks().forEach(track => {
        this.participant.publishTrack(track);
      });
    }
  }

  attach(participant) {
    this.unpublish();
    this.participant = participant;
    const videoTracks = Array.from(participant.videoTracks.values());
    const audioTracks = Array.from(participant.audioTracks.values());
    this.data = new DataTrack(participant);
    this.video = new MediaStream(videoTracks.map(p => p.track.mediaStreamTrack));
    this.audio = new MediaStream(audioTracks.map(p => p.track.mediaStreamTrack));
    this.publish();
    if (!this.isInspector) {
      this.data.watchPosition();
    }
  }

  detach() {
    this.stopAudio();
    this.stopVideo();
    if (this.participant) {
      this.participant.tracks.forEach(publication => {
        if (publication.track.mediaStreamTrack) {
          publication.track.mediaStreamTrack.stop();
        }
      });
    }
  }

  destroy() {
    this.unpublish();
    super.destroy();
  }

  setVideo(stream) {
    this.unpublish();
    super.setVideo(stream);
    this.publish();
  }

  setAudio(stream) {
    this.unpublish();
    super.setAudio(stream);
    this.publish();
  }

  setPointer(pointer) {
    super.setPointer(pointer);
    this.data.sendPointer(pointer);
  }

  async setInputDevice(deviceId) {
    const audioTrack = Array.from(this.participant.audioTracks.values())[0];
    if (!audioTrack) {
      try {
        const newTrack = await Video.createLocalAudioTrack({ deviceId: deviceId });
        this.participant.publishTrack(newTrack);
      } catch (e) {
        console.error('Failed to create local track: ', e);
      }
    } else {
      const track = audioTrack.track;
      track.restart({ deviceId });
    }
  }
}
