import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PDFJSRender from '../shared/PDFJSRender';
import Spinner from '../../Spinner/index';
import ErrorContent from '../shared/ErrorContent';
import Autoscale from '../shared/Autoscale';
import styles from './PDFView.scss';

export default class PDFView extends Component {
  static propTypes = {
    file: PropTypes.string.isRequired,
    width: PropTypes.number,
    schema: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    selectedItemId: PropTypes.any,
    isFieldSelected: PropTypes.func,
    resetSelection: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    resetSelection: null,
    selectedItemId: null,
    isFieldSelected: () => false,
    width: null,
    className: null,
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      scale: 1,
      error: null,
    };
    this.autoscaleRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { width } = this.props;
    const { width: oldWidth } = prevProps;

    if (width !== oldWidth) {
      this.autoscaleRef.current.updateWidth();
    }
  }

  onDocumentLoad = ({ scale }) => {
    this.setState({ scale, loaded: true, error: null });
    this.autoscaleRef.current.updateWidth();
  };

  onDocumentError = error => {
    this.setState({ error });
  };

  render() {
    const {
      schema,
      renderItem,
      children,
      file,
      width,
      selectedItemId,
      isFieldSelected,
      resetSelection,
      className,
    } = this.props;
    const { scale, loaded, error } = this.state;

    return (
      <div className={classNames(styles.Container, className)}>
        <div
          className={classNames(styles.PDFOverlay, {
            [styles.Loaded]: loaded,
            [styles.Hide]: !!error,
          })}
          onClick={resetSelection}
          role="button"
          tabIndex="0"
        >
          <Autoscale ref={this.autoscaleRef}>
            {containerWidth => (
              <PDFJSRender
                file={file}
                width={width || containerWidth}
                onSetScale={this.onSetScale}
                onLoad={this.onDocumentLoad}
                onError={this.onDocumentError}
              />
            )}
          </Autoscale>
        </div>
        {error && <ErrorContent error={error} />}
        {!error &&
          (loaded ? (
            <Fragment>
              {schema.map(field =>
                renderItem(field, {
                  selected: isFieldSelected(field, selectedItemId),
                  selectItem: this.onSelect,
                  scale,
                }),
              )}
              {children}
            </Fragment>
          ) : (
            <div className={styles.Spinner}>
              <Spinner inverse />
            </div>
          ))}
      </div>
    );
  }
}
