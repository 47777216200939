import queryString from 'query-string';

import { ENDPOINT, makeHeaders, request } from './';

export const token = async (id, identity) => {
  const body = JSON.stringify({ name: id, identity });
  const response = await request(`${ENDPOINT}/token`, {
    method: 'post',
    body,
    headers: makeHeaders(),
  });
  const data = await response.json();
  return data.token;
};

export const auth = async () => {
  try {
    const response = await request(`${ENDPOINT}/auth`, {
      method: 'get',
      headers: makeHeaders(),
    });
    return response.status === 200;
  } catch (e) {
    return false;
  }
};

export const fetch = async id => {
  try {
    const response = await request(`${ENDPOINT}/inspections/${id}`);
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const list = async (offset, sorter, query, limit, filter) => {
  try {
    const organizationId = window.localStorage.getItem('caminoOrganizationID');
    const params = queryString.stringify({
      offset,
      limit,
      ...sorter,
      query,
      organizationId,
      filter,
    });
    const uri = `${ENDPOINT}/inspections?${params}`;
    const response = await request(uri, { method: 'get', headers: makeHeaders() });
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const create = async data => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections`, {
    method: 'post',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const schedule = async data => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/schedule`, {
    method: 'post',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const requestInspection = async data => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/request`, {
    method: 'POST',
    body,
    headers: makeHeaders(true),
  });
  return await response.json();
};

export const checkStatus = async ({ internalRecordNumber, organizationId }) => {
  const params = queryString.stringify({ internalRecordNumber, organizationId });
  const response = await request(`${ENDPOINT}/inspections/status?${params}`, {
    method: 'get',
    headers: makeHeaders(true),
  });
  return await response.json();
};

export const update = async (id, data) => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/${id}`, {
    method: 'put',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const destroy = async id => {
  const response = await request(`${ENDPOINT}/inspections/${id}`, {
    method: 'delete',
    headers: makeHeaders(),
  });
  return await response.json();
};

export const start = async (id, data) => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/${id}/start`, {
    method: 'post',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const complete = async (id, data) => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/${id}/complete`, {
    method: 'put',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const close = async (id, data) => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/${id}/close`, {
    method: 'put',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const confirm = async (id, data) => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/${id}/confirm`, {
    method: 'put',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const cancel = async (id, data) => {
  const body = JSON.stringify(data);
  const response = await request(`${ENDPOINT}/inspections/${id}/cancel`, {
    method: 'put',
    body,
    headers: makeHeaders(),
  });
  return await response.json();
};

export const download = async (id, kind) => {
  const response = await request(`${ENDPOINT}/inspections/${id}/download/${kind}`, {
    method: 'get',
    headers: makeHeaders(),
  });
  const data = await response.json();
  window.open(data.uri);
};

export const exportAllUrl = async params => {
  return `${ENDPOINT}/inspections/export?${queryString.stringify(params)}`;
};

export const sendNotification = async (id, type) => {
  const body = JSON.stringify({ type });
  await request(`${ENDPOINT}/inspections/${id}/notifications`, {
    method: 'POST',
    body,
    headers: makeHeaders(),
  });
};

export const addInspector = async (id, assignee) => {
  const body = JSON.stringify({ assignee });
  const resp = await request(`${ENDPOINT}/inspections/${id}/assignees`, {
    method: 'POST',
    body,
    headers: makeHeaders(),
  });
  return await resp.json();
};

export const removeInspector = async (id, assignee) => {
  const body = JSON.stringify({ assignee });
  const resp = await request(`${ENDPOINT}/inspections/${id}/assignees`, {
    method: 'DELETE',
    body,
    headers: makeHeaders(),
  });
  return await resp.json();
};
