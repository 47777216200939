import Video from 'twilio-video';
import EventEmitter from 'events';
import '@peermetrics/webrtc-stats';
import history from '../utils/history';
import { isSafari } from '../utils/utils';
import { Logger } from '../utils/analytics';
import { InspectionService } from './services';
import { createConnectionConfig } from './utils';
import { setRemoteNoteCapture } from '../redux/actions';
import { RemoteParticipant, LocalParticipant } from './participants';

export default class Room {
  constructor() {
    this.name = null;
    this.local = null;
    this.remote = [];
    this.isAdmin = false;
    this.eventEmitter = new EventEmitter();
  }

  disconnect() {
    if (this.room) {
      this.room.disconnect();
      if (this.local) {
        this.local.detach();
      }

      this.eventEmitter.emit('leave');
    }
  }

  setZoomLevel(zoom) {
    if (this.local) {
      this.local.setZoomLevel(zoom);

      if (this.local.data) {
        this.local.data.sendZoomLevel(this.local.zoomLevel);
      }

      this.eventEmitter.emit('setState');
    }
  }

  toggleFlashlight(flashlightToggled) {
    if (this.local) {
      this.local.toggleFlashlight(flashlightToggled);

      // Emit to clients for syncing
      if (this.local.data) {
        this.local.data.sendFlashlightToggled(this.local.flashlightToggled);
      }

      this.eventEmitter.emit('setState');
    }
  }

  togglePointer(pointerToggled) {
    if (this.local) {
      this.local.togglePointer(pointerToggled);

      // Emit to clients for syncing
      if (this.local.data) {
        this.local.data.sendPointerToggled(this.local.pointerToggled);
      }

      this.eventEmitter.emit('setState');
    }
  }

  setPointer(pointer) {
    if (this.local) {
      this.local.setPointer(pointer);

      // Emit to clients for syncing
      if (this.local.data) {
        this.local.data.sendPointer(this.local.pointer);
      }

      this.eventEmitter.emit('setState');
    }
  }

  startVideo() {
    if (this.local) {
      this.local.startVideo();
      this.eventEmitter.emit('setState');
    }
  }

  stopVideo() {
    if (this.local) {
      this.local.stopVideo();
      this.eventEmitter.emit('setState');
    }
  }

  startAudio() {
    if (this.local) {
      this.local.startAudio();
      this.eventEmitter.emit('setState');
    }
  }

  stopAudio() {
    if (this.local) {
      this.local.stopAudio();
      this.eventEmitter.emit('setState');
    }
  }

  setInputDevice(deviceId) {
    this.local.setInputDevice(deviceId);
  }

  async start(isAdmin, isRoomOwner, name, identity, connectionSettings) {
    this.name = name;
    this.remote = [];
    this.isAdmin = isAdmin;
    this.isRoomOwner = isRoomOwner;
    this.connectionSettings = connectionSettings;
    this.local = new LocalParticipant(identity, isAdmin);
    this.eventEmitter.emit('setState');
    await this._connect();
  }

  onLeave(callback) {
    this.eventEmitter.on('leave', callback);
  }

  onSourceUpdate(callback) {
    this.eventEmitter.on('setState', () => {
      const keys = [
        'id',
        'networkStatistics',
        'zoomLevel',
        'flashlightToggled',
        'pointerToggled',
        'audioMuted',
        'videoMuted',
        'network',
        'pointer',
        'position',
        'identity',
        'audio',
        'video',
      ];
      const copy = item => keys.reduce((acc, key) => ({ ...acc, [key]: item[key] }), {});
      const local = copy(this.local || {});
      const remote = this.remote.map(r => copy(r || {}));
      callback({ local, remote });
    });
  }

  // Private
  _monitorStatistics() {
    if (isSafari()) return;

    const signaling = this.room._signaling;
    this.statistics = new window.WebRTCStats({ getStatsInterval: 10000 });
    this.statistics.on('stats', stats => {
      if (this.local) {
        const identity = this.local.identity;
        Logger.info({ identity, stats });
      }
    });

    signaling.on('connectionStateChanged', () => {
      const connections = [...signaling._peerConnectionManager._peerConnections.values()];
      connections.forEach(connection => {
        const peerId = connection.id;
        const pc = connection._peerConnection._peerConnection;
        if (!this.statistics.peersToMonitor[peerId]) {
          this.statistics.addPeer({ pc, peerId });
        }
      });
    });
  }

  async _connect() {
    try {
      const token = await InspectionService.token(this.name, this.local.identity);

      this.room = await Video.connect(
        token,
        createConnectionConfig(this.name, this.isAdmin, this.connectionSettings),
      );

      this._monitorStatistics();

      window.addEventListener('beforeunload', () => this.room.disconnect());
      this.local.attach(this.room.localParticipant);
      this.eventEmitter.emit('setState');

      this.room.participants.forEach(participant => {
        this._addParticipant(participant);
      });

      this.room.on('participantConnected', participant => {
        this._addParticipant(participant);
      });

      this.room.on('disconnected', () => this._participantDisconnected());
      this.room.on('participantDisconnected', participant =>
        this._participantDisconnected(participant),
      );
    } catch (e) {
      console.error(e);
      history.push(`/error?code=${e.code || `connect-room-error`}`);
    }
  }

  _addTrack(participant, track) {
    const remote = this.remote.find(r => r.identity === participant.identity);
    if (!remote) return;

    this._handleTrackData(remote, track);
    if (track.mediaStreamTrack) {
      const stream = new MediaStream([track.mediaStreamTrack]);
      switch (track.kind) {
        case 'video':
          remote.setVideo(stream);
          break;
        case 'audio':
          remote.setAudio(stream);
          break;
        default:
          break;
      }
    }

    this.eventEmitter.emit('setState');
    track.on('dimensionsChanged', () => this.eventEmitter.emit('setState'));
  }

  _addParticipant(participant) {
    const remote = new RemoteParticipant(participant.identity, false, participant);
    this.remote.push(remote);
    this.eventEmitter.emit('setState');

    if (!this.isAdmin) {
      this.local.data.sendPosition();
    }

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        this._addTrack(participant, publication.track);
      }

      publication.on('subscribed', track => {
        this._addTrack(participant, track);
      });
    });

    participant.on('trackPublished', publication => {
      publication.on('subscribed', track => this._addTrack(participant, track));
    });

    participant.on('trackAdded', track => this._handleTrackData(remote, track));
    participant.on('networkQualityLevelChanged', (network, networkStatistics) => {
      Logger.info({
        event: 'networkQualityLevelChanged',
        identity: this.local.identity,
        participant: participant.identity,
        networkStatistics,
      });
      remote.setNetwork(network);
      remote.setNetworkStatistics(networkStatistics);
      this.eventEmitter.emit('setState');
    });
  }

  _handleTrackData(remote, track) {
    if (track.kind === 'data') {
      track.on('message', data => {
        if (remote) {
          const response = JSON.parse(data);
          switch (response.type) {
            case 'position':
              remote.setPosition(response.data);
              InspectionService.update(this.name, response.data);
              this.eventEmitter.emit('setState');
              break;
            case 'pointer':
              remote.setPointer(response.data);
              this.eventEmitter.emit('setState');
              break;
            case 'zoom':
              this.local.setZoomLevel(response.data);
              this.eventEmitter.emit('setState');
              break;
            case 'flashlightToggled':
              this.local.toggleFlashlight(response.data);
              this.eventEmitter.emit('setState');
              break;
            case 'pointerToggled':
              this.local.togglePointer(response.data);
              this.eventEmitter.emit('setState');
              break;
            default:
              throw new Error(`Unhandled data track ${response.type}`);
          }
        }
      });
    }
  }

  async _participantDisconnected(participant) {
    if (this.remote) {
      if (participant) {
        this.remote = this.remote.filter(r => r.identity !== participant.identity);
      } else {
        this.remote = [];
      }
    }

    const inspection = await InspectionService.fetch(this.name);

    if (
      (inspection.status === 'completed' || inspection.status === 404) &&
      !this.isRoomOwner
    ) {
      if (this.local) {
        this.local.detach();
        this.local = null;
      }

      this.eventEmitter.emit('leave');
    } else if (this.isAdmin && participant) {
      window.alert(`${participant.identity} disconnected`);
    }

    this.eventEmitter.emit('setState');
  }
}
