export const convertDuration = duration => {
  const milliseconds = parseInt((duration % 1000) / 100);
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
};

export const isSafari = () => {
  return /iPad|iPhone|iPod/i.test(window.navigator.userAgent) && !window.MSStream;
};

export const isMobile = () => {
  /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(window.navigator.userAgent);
};
