import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import bowser from 'bowser';
import { Document, Page, pdfjs } from 'react-pdf';
import { UNSUPPORTED_BROWSER } from './ErrorContent';
import './PDFJSRender.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class PDFJSRenderer extends PureComponent {
  static propTypes = {
    file: PropTypes.string.isRequired,
    width: PropTypes.number,
    onLoad: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    width: null,
  };

  state = {
    page: null,
    numPages: null,
  };

  componentDidMount() {
    if (bowser.msie && bowser.version === '10.0') {
      const { onError } = this.props;
      onError(UNSUPPORTED_BROWSER);
    }
  }

  componentDidUpdate(prevProps) {
    const { width: oldWidth } = prevProps;
    const { width, onLoad } = this.props;
    const { page } = this.state;

    if (page && oldWidth !== width) {
      onLoad({ scale: this.getScale(width, page) });
    }
  }

  getScale = (width, page) => {
    if (page.rotate === 90 || page.rotate === 270) {
      return width / page.originalHeight;
    }

    return width / page.originalWidth;
  };

  onDocumentError = error => {
    const { onError } = this.props;
    onError(error);
  };

  onDocumentLoadSuccess = pdfInfo => {
    this.setState({
      numPages: pdfInfo.numPages,
    });
  };

  onPageLoad = page => {
    const { width, onLoad } = this.props;
    if (page.pageIndex === 0) {
      this.setState(
        {
          page,
        },
        () => {
          onLoad({ scale: this.getScale(width, page) });
        },
      );
    }
  };

  render() {
    const { file, width } = this.props;
    const { numPages } = this.state;

    return (
      <Document
        file={file}
        onLoadSuccess={this.onDocumentLoadSuccess}
        onLoadError={this.onDocumentError}
        onSourceError={this.onDocumentError}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={width}
            onLoadSuccess={this.onPageLoad}
          />
        ))}
      </Document>
    );
  }
}
