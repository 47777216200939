import React, { PureComponent } from 'react';
import { AutoComplete } from 'antd';
import classnames from 'classnames';
import Field from '../ReduxFormFields/Field';
import styles from './AutoCompleteField.scss';

const AutoCompleteField = props => {
  const {
    className,
    dataSource = [],
    disabled,
    id,
    input = {},
    inputOverride = {},
    meta,
    placeholder,
    readOnly,
    renderOption,
    type,
    ...restProps
  } = props;

  const hasError = meta && meta.dirty && meta.error;

  const renderer = renderOption
    ? {
        children: dataSource.map(
          renderOption.bind(null, { Option: AutoComplete.Option }),
        ),
      }
    : { dataSource };

  return (
    <Field {...props} className={classnames(styles.TextField, className)}>
      <AutoComplete
        id={id}
        type={type}
        readOnly={readOnly}
        placeholder={placeholder}
        className={classnames(input.className, {
          [styles.hasError]: hasError,
          [styles.Disabled]: disabled,
        })}
        disabled={disabled}
        {...renderer}
        {...restProps}
        {...input}
        {...inputOverride}
      />
    </Field>
  );
};

export default AutoCompleteField;
