import { v4 as uuidv4 } from 'uuid';

export const LOG_LEVEL = undefined;
export const SCREEN_CONFIG = { audio: false, video: { cursor: 'always' } };
export const CAMERA_CONFIG = { audio: true, video: { facingMode: 'environment' } };

export const sleep = async time => new Promise(resolve => setTimeout(resolve, time));

export const getName = (kind, isAdmin, id = '') => {
  const prefix = isAdmin ? 'inspector' : 'attendee';
  return `${prefix}${id}-${kind}`;
};

export const createConnectionConfig = (name, isAdmin, connectionSettings) => {
  const id = isAdmin ? uuidv4() : '';
  return {
    maxAudioBitrate: isAdmin ? null : 16000,
    audio: { name: getName('audio', isAdmin, id) },
    ...connectionSettings,
    name: name,
    video: isAdmin
      ? false
      : {
          ...connectionSettings.video,
          name: getName('video', isAdmin),
          facingMode: 'environment',
        },
    logLevel: LOG_LEVEL,
    workaroundWebKitBug180748: true,
    networkQuality: { local: 3, remote: 3 },
    preferredVideoCodecs: ['VP8'],
  };
};

export const fetchPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => resolve(position),
      err => reject(err),
    );
  });
};
