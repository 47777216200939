import { fitFrameOnCanvas } from '../components/Canvas/utils';

export const captureStream = (stream, zoom = 1, type = 'image/png', quality = 1) => {
  return new Promise(resolve => {
    const track = stream.getVideoTracks()[0];

    if (!track) {
      console.warn('Attempted to capture stream before it was ready');
      return;
    }

    const width = track.getSettings().width || 100;
    const height = track.getSettings().height || 100;
    const video = document.createElement('video');
    video.width = width;
    video.height = height;
    video.srcObject = stream;
    video.autoplay = true;
    video.playsInline = true;
    video.addEventListener('loadeddata', () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      const dimensions = { width, height };
      fitFrameOnCanvas(ctx, video, dimensions, dimensions, zoom);

      // Clean up video players to avoid "too many WebMediaPlayer" error in Chrome 92+.
      // See https://chromium-review.googlesource.com/c/chromium/src/+/2816118
      video.remove();
      video.srcObject = null;

      resolve(canvas.toDataURL(type, quality));
    });
  });
};
