import { sleep } from './utils';

export const Video = 'video';
export const Audio = 'audio';
export const Location = 'location';

export const Denied = 'Denied';
export const Pending = 'Pending';
export const Unknown = 'Unknown';
export const Approved = 'Approved';

const Descriptions = {
  async video() {
    const query = await Descriptions.query('camera');
    if (query === Approved) return Approved;
    const result = await Descriptions.userMedia({
      audio: false,
      video: { facingMode: 'environment' },
    });
    await sleep(5000);
    return result;
  },

  async audio() {
    const query = await Descriptions.query('microphone');
    if (query === Approved) return Approved;
    return await Descriptions.userMedia({ audio: true, video: false });
  },

  async location() {
    const checkLocationPermission = new Promise(async resolve => {
      const query = await Descriptions.query('geolocation');
      if (query === Approved) return resolve(Approved);

      navigator.geolocation.getCurrentPosition(
        () => resolve(Approved),
        err => {
          console.warn(err);
          resolve(Denied);
        },
      );
    });

    return await checkLocationPermission;
  },

  async query(name) {
    if (navigator.permissions && navigator.permissions.query) {
      try {
        const result = await navigator.permissions.query({ name });
        if (result.state === 'granted') return Approved;
      } catch (_) {
        // noop - permissions.query is broken in firefox
      }
    }
  },

  async userMedia(constraints) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      stream.getTracks().forEach(track => {
        track.stop();
        stream.removeTrack(track);
      });

      return Approved;
    } catch (err) {
      console.warn(err);
      return Denied;
    }
  },
};

export const checkPermissions = async (required = [], optional = []) => {
  await Promise.all(optional.map(name => Descriptions[name]()));
  return await Promise.all(
    required.map(name => {
      return Descriptions[name]().then(status => ({ name, status }));
    }),
  );
};

const permissions = {
  Video,
  Audio,
  Denied,
  Unknown,
  Pending,
  Approved,
  Location,
  checkPermissions,
};

export default permissions;
