import qs from 'query-string';
import { ENDPOINT, request, makeHeaders } from './';

export const fetch = async organizationId => {
  if (!organizationId) {
    return null;
  }
  try {
    const uri = `${ENDPOINT}/organizations/${organizationId}`;
    const response = await request(uri, { method: 'get', headers: makeHeaders() });
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const fetchBySlug = async organizationSlug => {
  if (!organizationSlug) {
    return null;
  }

  try {
    const response = await request(
      `${ENDPOINT}/organizations/bySlug?slug=${organizationSlug}`,
      { method: 'get', headers: makeHeaders(true) },
    );
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const fetchUsers = async (organizationId, params) => {
  if (!organizationId) {
    return null;
  }

  try {
    const response = await request(
      `${ENDPOINT}/organizations/${organizationId}/users?${qs.stringify(params)}`,
      { method: 'get', headers: makeHeaders() },
    );
    return await response.json();
  } catch (e) {
    return null;
  }
};
