import { LocalDataTrack } from 'twilio-video';

import { sleep } from './utils';

const getPosition = options =>
  new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  );

const positionAction = position =>
  JSON.stringify({
    type: 'position',
    data: {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    },
  });

const pointerAction = pointer =>
  JSON.stringify({
    type: 'pointer',
    data: pointer,
  });

const zoomAction = zoom =>
  JSON.stringify({
    type: 'zoom',
    data: zoom,
  });

const flashlightAction = flashlightToggled =>
  JSON.stringify({
    type: 'flashlightToggled',
    data: flashlightToggled,
  });

const pointerToggleAction = pointerToggled =>
  JSON.stringify({
    type: 'pointerToggled',
    data: pointerToggled,
  });
export default class DataTrack {
  constructor(participant) {
    this.track = new LocalDataTrack();
    participant.publishTrack(this.track);

    this.promise = new Promise((resolve, reject) => {
      this._reject = reject;
      this._resolve = resolve;
    });

    participant.on('trackPublished', publication => {
      if (publication.track === this.track) {
        this._resolve();
      }
    });

    participant.on('trackPublicationFailed', (error, track) => {
      if (track === this.track) this._reject(error);
    });
  }

  watchPosition() {
    const onSuccess = async position => {
      await this.promise;
      this.track.send(positionAction(position));
    };

    const onError = e => console.error(e);

    try {
      navigator.geolocation.watchPosition(onSuccess, onError);
    } catch (e) {
      onError(e);
    }
  }

  async sendPosition() {
    await this.promise;
    // @TODO
    // We waited until the track was published, but it might take
    // some time for the remote to subscribe. A possible solution is to
    // send an acknowledgment when the remote has subscribed and wait here
    // for that acknowledgment.
    await sleep(10000);
    try {
      const position = await getPosition();
      this.track.send(positionAction(position));
    } catch (e) {
      console.error(e);
    }
  }

  async sendPointer(pointer) {
    await this.promise;
    this.track.send(pointerAction(pointer));
  }

  async sendZoomLevel(zoom) {
    await this.promise;
    this.track.send(zoomAction(zoom));
  }

  async sendFlashlightToggled(flashlightToggled) {
    await this.promise;
    this.track.send(flashlightAction(flashlightToggled));
  }

  async sendPointerToggled(pointerToggled) {
    await this.promise;
    this.track.send(pointerToggleAction(pointerToggled));
  }
}
