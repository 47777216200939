import React, { lazy, useEffect, Suspense, useState } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Spinner } from '@caminoai/ui';
import decode from 'jwt-decode';
import get from 'lodash/get';
import { useLocalStorage } from '../hooks';
import { init as initAnalytics } from '../utils/analytics';
import { AppProvider, SearchParamProvider } from '../context';
import { useMobileDetect } from '../hooks';
import history from '../utils/history';
import { setAppTimezone } from '../utils/time';
import { store } from '../redux/store';
import { OrganizationService, SettingsService } from '../api/services';
import { AUTHORIZATION_KEY, ORGANIZATION_ID_KEY } from '../constants';
import ErrorBoundary from '../components/ErrorBoundary';
import ProtectedRoute from './ProtectedRoute';
import styles from './index.scss';

const OAuth = lazy(() => import('./OAuth'));
const Expired = lazy(() => import('./Expired'));
const Help = lazy(() => import('./Help'));
const TestPage = lazy(() => import('./Test'));
const ErrorPage = lazy(() => import('./Error'));
const Notifications = lazy(() => import('./Notifications'));
const Join = lazy(() => import('./Session/Join'));
const StartSession = lazy(() => import('./Session/Start/StartSession'));
const EndSession = lazy(() => import('./Session/EndSession'));
const AttendeeSession = lazy(() => import('./Session/AttendeeSession'));
const InspectorSession = lazy(() => import('./Session/InspectorSession'));
const Inspections = lazy(() => import('./Inspections'));
const ScheduleInspection = lazy(() => import('./Inspections/Schedule'));
const InspectionDetails = lazy(() => import('./Inspections/InspectionDetails'));
const CitizenPortal = lazy(() => import('./CitizenPortal'));
const Settings = lazy(() => import('./Settings'));

const EPOCH_DAY_IN_SECONDS = 24 * 60 * 60; // 24h * 60m * 60s

const getCurrentUser = token => {
  try {
    if (!token) {
      return null;
    }

    const decoded = decode(token);
    const iat = get(decoded, 'iat');
    const exp = iat + EPOCH_DAY_IN_SECONDS;

    return {
      id: get(decoded, 'sub'),
      name: get(decoded, 'name'),
      email: get(decoded, 'email'),
      iat,
      exp,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

const App = () => {
  const [appReady, setAppReady] = useState(false);
  const [organizationId] = useLocalStorage(ORGANIZATION_ID_KEY, '');
  const [token] = useLocalStorage(AUTHORIZATION_KEY, '');
  const { isMobile, isTablet } = useMobileDetect();
  const [organization, setOrganization] = useState(null);
  const currentUser = getCurrentUser(token);

  async function onFetchOrganization(currentOrgId) {
    try {
      setAppReady(false);

      const [org, terminology] = await Promise.all([
        OrganizationService.fetch(currentOrgId),
        SettingsService.fetchTerminology(currentOrgId),
      ]);

      if (org) {
        setAppTimezone(org.timezone);
        setOrganization({
          ...org,
          terminology,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAppReady(true);
    }
  }

  useEffect(() => {
    initAnalytics();
    onFetchOrganization(organizationId);
  }, [organizationId]);

  return (
    <Provider store={store}>
      <Router history={history}>
        <AppProvider
          value={{
            organizationId,
            organization,
            token,
            isMobile,
            isTablet,
            currentUser,
            setOrganization,
          }}
        >
          <SearchParamProvider>
            <ErrorBoundary>
              <Suspense
                fallback={
                  <div className={styles.spinner}>
                    <Spinner fullWidth />
                  </div>
                }
              >
                <Notifications />
                {appReady ? (
                  <Switch>
                    <Route path="/help" component={Help} />
                    <Route exact path="/oauth-callback" component={OAuth} />
                    <Route exact path="/error" component={ErrorPage} />
                    <Route exact path="/test" component={TestPage} />
                    <Route exact path="/expired" component={Expired} />
                    <Route exact path="/join" component={Join} />
                    <ProtectedRoute path="/settings" component={Settings} />
                    <Route
                      path={['/view/:id', '/admin/:id']}
                      component={InspectorSession}
                    />
                    <ProtectedRoute
                      path={['/start/:id', '/start']}
                      component={StartSession}
                    />
                    <ProtectedRoute path="/schedule" component={ScheduleInspection} />
                    <ProtectedRoute
                      path="/inspection/:id"
                      component={InspectionDetails}
                    />
                    <Route path="/session/:id/end" component={EndSession} />
                    <Route path="/session/:id" component={AttendeeSession} />
                    <Route path="/:organizationSlug" component={CitizenPortal} />
                    <ProtectedRoute path="/" component={Inspections} />
                  </Switch>
                ) : (
                  <div className={styles.spinner}>
                    <Spinner fullWidth />
                  </div>
                )}
              </Suspense>
            </ErrorBoundary>
          </SearchParamProvider>
        </AppProvider>
      </Router>
    </Provider>
  );
};

export default App;
