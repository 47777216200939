import { useEffect, useRef, useState } from 'react';

const useThrottle = (value, ms) => {
  const [state, setState] = useState(value);
  const timeout = useRef();
  const nextValue = useRef(null);
  const hasNextValue = useRef(0);

  useEffect(() => {
    if (!timeout.current) {
      setState(value);

      // Don't start throttling until a non-null value is provided.
      if (value === null) return;

      const timeoutCallback = () => {
        if (hasNextValue.current) {
          hasNextValue.current = false;
          setState(nextValue.current);
          timeout.current = setTimeout(timeoutCallback, ms);
        } else {
          timeout.current = undefined;
        }
      };
      timeout.current = setTimeout(timeoutCallback, ms);
    } else {
      nextValue.current = value;
      hasNextValue.current = true;
    }
  }, [value]);

  // Clean up pending timer if the component unmounts
  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
    },
    [],
  );

  return state;
};

export default useThrottle;
