import { useEffect, useState } from 'react';

const KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const ENDPOINT = 'https://maps.googleapis.com/maps/api/geocode/json';

const reverseGeocode = async coordinate => {
  const uri = `${ENDPOINT}?latlng=${coordinate.latitude},${coordinate.longitude}&result_type=street_address&key=${KEY}`;

  const response = await fetch(uri);
  const data = await response.json();

  if (
    data &&
    data.results &&
    data.results.length > 0 &&
    data.results[0].formatted_address
  ) {
    return data.results[0].formatted_address;
  }

  return '';
};

const useCoordinate = coordinate => {
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (coordinate) {
      reverseGeocode(coordinate)
        .then(formatted => setAddress(formatted))
        .catch(e => {
          console.error(e);
          setAddress('');
        });
    }
  }, [coordinate]);

  return address;
};

export default useCoordinate;
