import { useReducer, useEffect, useState } from 'react';
import identity from 'lodash/identity';
import { v4 as uuidv4 } from 'uuid';
import usePrevious from './usePrevious';

/**
 * Reducer that handle useDataApi hook action types
 * @param state
 * @param action
 */
const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

/**
 * Async promise waiting function
 * @param ms
 * @returns {Promise<any>}
 */
const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * A generic http handler hook that integrates into the client `service` method
 * @param initialUrl
 * @param body
 * @param method
 * @param initialData
 * @param transform
 * @param throttleMs
 * @param mockData
 */
const useDataApi = ({
  url,
  body,
  method = 'get',
  initialData = {},
  transform = identity,
  throttleMs,
  mockData,
  onSuccess,
  service,
}) => {
  if (!service) {
    throw new Error('`service` is required');
  }

  const [requestKey, setRequestKey] = useState();
  const previousRequestKey = usePrevious(requestKey);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    error: null,
    data: initialData,
  });
  const refresh = () => setRequestKey(uuidv4());

  useEffect(() => {
    // Exit early if no url is defined
    if (!url) {
      return;
    }
    refresh();
  }, [url]);

  let controller;

  useEffect(() => {
    // Exit early if there's no request to perform.
    if (!requestKey || requestKey === previousRequestKey) {
      return;
    }

    let didCancel = false;

    (async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        if (typeof AbortController !== 'undefined') {
          controller = new AbortController();
        }

        const data = await service(method, url, body, {
          signal: controller ? controller.signal : null,
        });

        // Moreso for debugging latency
        if (throttleMs) {
          await wait(throttleMs);
        }

        if (!didCancel) {
          const payload = transform(mockData || data);
          if (onSuccess) {
            onSuccess(payload);
          }
          dispatch({
            type: 'FETCH_SUCCESS',
            payload,
          });
        }
      } catch (error) {
        if (!didCancel) {
          const mockPayload = mockData
            ? { payload: transform(mockData) }
            : { payload: error };
          dispatch({
            type: 'FETCH_FAILURE',
            ...mockPayload,
          });
        }
      }
    })();

    // eslint-disable-next-line
    return () => {
      if (!didCancel) {
        didCancel = true;
        if (controller) {
          controller.abort();
        }
      }
    };
  }, [requestKey]);

  return [state, refresh];
};

export default useDataApi;
