import { useState, useEffect } from 'react';
import last from 'lodash/last';
import debounce from 'lodash/debounce';
import { leaveInspection, setNoteCapture, sendNotification } from '../redux/actions';
import Room from '../api/Room';
import { captureStream } from './utils';

const room = new Room();

const getStartTime = inspection => {
  if (!inspection || !inspection.dateCreated) return new Date();
  return new Date(inspection.dateCreated);
};

const useVideo = (inspection, dispatch, isInspector, isRoomOwner, connectionSettings) => {
  const [state, setState] = useState({
    local: {},
    remote: [],
    startTime: getStartTime(inspection),
    inputDeviceId: 'default',
    outputDeviceId: 'default',
  });

  useEffect(() => {
    room.start(
      isInspector,
      isRoomOwner,
      inspection.uniqueName,
      inspection.identity,
      connectionSettings,
      dispatch,
    );
    room.onSourceUpdate(({ local, remote }) =>
      setState(state => ({ ...state, local, remote })),
    );
    room.onLeave(() => dispatch(leaveInspection()));
  }, []);

  const zoomIn = () => {
    room.setZoomLevel(state.local.zoomLevel + 1);
  };

  const zoomOut = () => {
    room.setZoomLevel(state.local.zoomLevel - 1);
  };

  const toggleFlashlight = () => {
    room.toggleFlashlight(!state.local.flashlightToggled);
  };

  const togglePointer = () => {
    room.togglePointer(!state.local.pointerToggled);
  };

  const muteVideo = () => {
    room.stopVideo();
  };

  const unmuteVideo = () => {
    room.startVideo();
  };

  const muteAudio = () => {
    room.stopAudio();
  };

  const unmuteAudio = () => {
    room.startAudio();
  };

  const changeAudioInput = deviceId => {
    room.setInputDevice(deviceId);
    setState({ ...state, inputDeviceId: deviceId });
  };

  const changeAudioOutput = deviceId => {
    setState({ ...state, outputDeviceId: deviceId });
  };

  const captureImage = async () => {
    const remote = state.remote.find(r => r.video);
    if (remote) {
      const capture = await captureStream(remote.video, state.local.zoomLevel);
      dispatch(setNoteCapture(capture));
    }
  };

  const disconnect = () => {
    room.disconnect();
  };

  const setPointer = pointer => {
    room.setPointer(pointer);
  };

  const createParticipant = (
    { id, network, pointer, identity, networkStatistics },
    isLocal,
  ) => {
    const name = identity && last(identity.split(':'));
    return { id, network, pointer, identity, name, isLocal, networkStatistics };
  };

  const getParticipants = () => {
    return [
      createParticipant(state.local, true),
      ...state.remote.map(r => createParticipant(r, false)),
    ].filter(p => !!p.identity);
  };

  const resendInvite = debounce(() => {
    dispatch(sendNotification('join'));
  }, 5000);

  const sendTroubleshootingLink = debounce(() => {
    dispatch(sendNotification('troubleshoot'));
  }, 5000);

  return [
    {
      ...state,
      participants: getParticipants(),
    },
    {
      toggleFlashlight,
      togglePointer,
      zoomIn,
      zoomOut,
      muteVideo,
      muteAudio,
      disconnect,
      setPointer,
      unmuteAudio,
      unmuteVideo,
      captureImage,
      resendInvite,
      sendTroubleshootingLink,
      changeAudioInput,
      changeAudioOutput,
    },
  ];
};

export default useVideo;
