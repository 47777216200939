import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';

import { getName } from '../utils';

export const createTrack = (track, isInspector, name) => {
  const klass = track.kind === 'video' ? LocalVideoTrack : LocalAudioTrack;
  const options = name ? { name } : { name: getName(track.kind, isInspector) };
  return new klass(track, options);
};

export const removeTracks = stream => {
  if (!stream) return;
  stream.getTracks(track => stream.removeTrack(track));
};
