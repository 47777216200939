import { useEffect, useState } from 'react';

export const Mobile = 'Mobile';
export const Tablet = 'Tablet';
export const Desktop = 'Desktop';

export const MobileMaxWidth = 767;
export const TabletMaxWidth = 1024;

export const getDescription = size => {
  if (size <= MobileMaxWidth) return Mobile;
  if (size <= TabletMaxWidth) return Tablet;
  return Desktop;
};

export const useScreen = () => {
  const [screen, setScreen] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const onResize = () => {
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });

  return [getDescription(screen.width), screen];
};
