import { batch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import orderBy from 'lodash/orderBy';
import history from '../utils/history';
import Permissions from '../api/Permissions';
import { SettingsService, NoteService, InspectionService } from '../api/services/index';

export const showNotification = payload => {
  return { type: 'SHOW_NOTIFICATION', payload };
};

export const clearNotification = () => {
  return { type: 'CLEAR_NOTIFICATION' };
};

export const setPermissionStatus = status => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { isAdmin, uniqueName },
    } = getState();

    if (!isAdmin && uniqueName) {
      const data = { attendee_permission_error: status === Permissions.Denied };
      await InspectionService.update(uniqueName, data);
    }

    return dispatch({ type: 'SET_PERMISSION_STATUS', payload: { status } });
  };
};

const processNotes = notes =>
  orderBy(
    notes.map(note => ({
      ...note,
      image: note.imagePath,
      date: new Date(note.capturedAt),
    })),
    'date',
    'asc',
  );

export const setNoteEditingMode = mode => {
  return { type: 'SET_EDITING_MODE', payload: mode };
};

export const setNoteCapture = capture => {
  return { type: 'SET_CAPTURE', payload: capture };
};

export const setRemoteNoteCapture = id => {
  return async dispatch => {
    const payload = await NoteService.fetchOne(id);
    return dispatch({ type: 'SET_REMOTE_CAPTURE', payload });
  };
};

export const addNote = data => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { uniqueName },
    } = getState();
    const payload = { ...data, date: new Date() };
    dispatch({ type: 'ADD_NOTE', payload });
    await NoteService.create(uniqueName, payload);
    const notes = await NoteService.fetch(uniqueName);
    dispatch({ type: 'SET_NOTES', payload: processNotes(notes || []) });
  };
};

export const updateNote = (id, data) => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { uniqueName },
    } = getState();
    const payload = { ...data, date: new Date() };
    dispatch({ type: 'ADD_NOTE', payload });
    await NoteService.update(id, payload);
    const notes = await NoteService.fetch(uniqueName);
    dispatch({ type: 'SET_NOTES', payload: processNotes(notes || []) });
  };
};

export const removeNote = id => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { uniqueName },
    } = getState();
    await NoteService.destroy(id);
    const notes = await NoteService.fetch(uniqueName);
    dispatch({ type: 'SET_NOTES', payload: processNotes(notes || []) });
  };
};

export const fetchInspection = (id, isAdmin = false) => {
  return async dispatch => {
    const payload = await InspectionService.fetch(id);
    if (payload) {
      batch(() => {
        dispatch({ type: 'SET_INSPECTION', payload: { ...payload, isAdmin } });
        dispatch({ type: 'SET_NOTES', payload: processNotes(payload.Notes || []) });
      });
    } else {
      history.push(`/expired`);
    }
  };
};

export const startInspection = (id, data) => {
  return async dispatch => {
    const payload = await InspectionService.start(id, data);

    if (payload.error) {
      dispatch({ type: 'SET_CREATE_INSPECTION_ERROR', payload: payload.error });
      return;
    }

    const identity = `inspector:${uuidv4()}:${payload.inspectorName}`;

    batch(() => {
      dispatch({ type: 'SET_INSPECTION', payload });
      dispatch({ type: 'SET_IDENTITY', payload: identity });
      dispatch({ type: 'SET_NOTES', payload: processNotes(payload.Notes || []) });
    });

    history.push(`/admin/${id}`);
  };
};

export const createInspection = data => {
  return async dispatch => {
    const payload = await InspectionService.create(data);

    if (payload.error) {
      dispatch({ type: 'SET_CREATE_INSPECTION_ERROR', payload: payload.error });
      return;
    }

    const identity = `inspector:${uuidv4()}:${payload.inspectorName}`;

    batch(() => {
      dispatch({ type: 'SET_INSPECTION', payload });
      dispatch({ type: 'SET_IDENTITY', payload: identity });
      dispatch({ type: 'SET_NOTES', payload: processNotes(payload.Notes || []) });
    });

    history.push(`/admin/${payload.uniqueName}`);
  };
};

export const completeInspection = updates => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { uniqueName },
    } = getState();

    const payload = await InspectionService.complete(uniqueName, updates);

    if (payload) {
      batch(() => {
        dispatch({ type: 'SET_INSPECTION', payload });
        dispatch({ type: 'CLEAR_IDENTITY' });
        dispatch({ type: 'SET_NOTES', payload: processNotes(payload.Notes || []) });
      });

      history.push(`/session/${uniqueName}/end?t=${payload.duration}`);
    } else {
      dispatch({ type: 'CLEAR_IDENTITY' });
      history.push(`/session/${uniqueName}/end`);
    }
  };
};

export const leaveInspection = () => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { uniqueName },
    } = getState();
    dispatch({ type: 'CLEAR_IDENTITY' });
    history.push(`/session/${uniqueName}/end`);
  };
};

export const joinInspection = (identity, inspectionId, referrer) => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { isAdmin },
    } = getState();

    const data = isAdmin
      ? { inspectorName: identity }
      : { attendeeName: identity, attendeePermissionError: false };

    const payload = await InspectionService.update(inspectionId, data);

    batch(() => {
      dispatch({ type: 'SET_INSPECTION', payload });
      dispatch({
        type: 'SET_IDENTITY',
        payload: isAdmin ? `inspector:${uuidv4()}:${identity}` : identity,
      });
    });

    history.push(decodeURIComponent(referrer));
  };
};

export const toggleInfo = () => {
  return { type: 'TOGGLE_INFO_MODAL' };
};

export const fetchDisclaimer = organizationId => {
  return async dispatch => {
    const payload = await SettingsService.fetchDisclaimer(organizationId);
    dispatch({ type: 'SET_DISCLAIMER', payload: payload || {} });
  };
};

export const fetchCustomRequestText = organizationId => {
  return async dispatch => {
    const payload = await SettingsService.fetchRequestText(organizationId);
    dispatch({ type: 'SET_CUSTOM_REQUEST_TEXT', payload: payload || {} });
  };
};

export const sendNotification = type => {
  return async (dispatch, getState) => {
    const {
      inspectionState: { uniqueName },
    } = getState();
    await InspectionService.sendNotification(uniqueName, type);
  };
};
