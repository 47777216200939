import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  const cached = useRef();
  useEffect(() => {
    cached.current = callback;
  }, [callback]);
  useEffect(() => {
    const fn = () => cached.current();
    let id = setInterval(fn, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
